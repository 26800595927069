/* 404 Page*/

.error-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .LinkToStartPage {
    text-align: center !important;
  }
  
  .error-code {
    display: inline-block;
    margin: 0;
    margin-right: 20px;
    padding: 0 23px 0 0;
    font-size: 24px;
    font-weight: 500;
    vertical-align: top;
    line-height: 49px;
    border-right: 1px solid rgba(0, 0, 0, .3);
  }
  
  .error-message {
    display: inline-block;
    text-align: left;
    line-height: 49px;
    height: 49px;
    vertical-align: middle;
  }
  
  .error-message h2 {
    font-size: 14px;
    font-weight: normal;
    line-height: 49px;
    margin: 0;
    padding: 0;
  }
  
  @media (prefers-color-scheme: dark) {
    .error-container {
        color: #fff;
        background: #202020;
    }
  
    .error-code {
        border-right: 1px solid rgba(255, 255, 255, .3);
    }
  }
  
  