  
  #root .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
    color: black;
  }
  
  /* Snow Theme */
  #root .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  
  #root .ql-editor {
    min-height: 18em;
  }
  

  /* CSS für die Positionierung der Toolbar */
div.quill-toolbar {
  position: relative;
}

div.quill-toolbar .ql-toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}


.ck-editor__editable {
  color: rgb(0, 0, 0) !important;
}