 /* Popup */


 .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .popup-box {
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .popup-title {
    font-size: 24px;
    margin-top: 0;
  }
  
  .popup-message {
    margin-bottom: 20px;
  }
  
  .popup-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  .popup-button:hover {
    background-color: #0069d9;
  }
  

  .exit-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px 7px;
    background-color: #ff0000;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  .exit-button:hover {
    background-color: #7b0000;
  }
  