.ban-btn {
    display: inline-block;
    padding: 8px 12px;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .ban-btn:hover {
    background-color: #4e4e4e;
  }

.admin-btn {
    display: inline-block;
    padding: 8px 12px;
    background-color: #b60000;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }

  
  .admin-btn:hover {
    background-color: #ff0000;
  }


  .error {
    background-color: #c21a1a !important;
    color: white !important;
    border-radius: 5px;
    padding: 8px 12px;
    border: #000000 !important;
  }

  .setting {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 2rem;
  }
  
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    vertical-align: middle;
    right: 0;
    left: auto;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bbbbbb;
    -webkit-transition: .4s;
    transition: .4s;
    
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2dd21b;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2dd21b;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  


.div1 {
  width: 100%;
  background-color: #4e4e4e;
  border: 1px solid #000000;
  border-radius: 7px;
  margin-top: 10px;
  }
.div2 {
  height: 30px;
  background-color: #049815;
  border-radius: 7px;
}
  
.div3 {
  text-align: center;
  margin-top: -26px;
  font-size: 1.2em;
}

.select-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-container select {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
}

.select-container select:focus {
  outline: none;
  border-color: #4285F4;
}

.select-container option {
  padding: 8px;
}




.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 5px 5px;
  width: 255px;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.day-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.day-button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-right: 0;
}

.day-button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: 0;
}

.day-button:hover {
  background-color: #2980b9;
}

.day-button:active {
  background-color: #115a8a;
}




.permission-item {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.permission-item:hover {
  background-color: red;  /* Rot beim Entfernen */
  color: white;
}

/* Wenn Sie später einen Zustand zum Hinzufügen haben möchten: */
.permission-item.add {
  background-color: transparent;
}

.permission-item.add:hover {
  background-color: green; /* Grün beim Hinzufügen */
}
