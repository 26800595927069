/* Newsletter Container Styles */
.newsletter-container {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  margin: 20px auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  text-align: center;
  line-height: 1.6;
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: bold;
}

/* Newsletter Form Styles */
.newsletter-form {
  display: flex;
  flex-direction: column; /* Stack input and button */
  gap: 10px; /* Space between input and button */
  margin-bottom: 20px;
  align-items: center;
}

.newsletter-form input[type=email], .newsletter-form button {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.2);
  width: 50%; 
}

/* Button Styles */
.newsletter-form button {
  background-color: #007bff;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.5s linear;
}

.newsletter-form button:hover {
  background-color: #0056b3;
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.4);
}

/* Unsubscribe Section Styles */
.unsubscribe-section {
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  margin-top: 20px;
}

.unsubscribe-section h1 {
  font-size: 20px;
}

.newsletter-description, .unsubscribe-section {
  max-width: 300px; 
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

/* Additional Styles for Newsletter Description */
.newsletter-description {
  max-width: 300px;
  margin: 0 auto; /* Remove margin to place it higher */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

/* Dark Mode Specific Styles */
@media (prefers-color-scheme: light) {
  .newsletter-container {
    background-color: #202020;
    color: #fff;
  }

  .newsletter-container h1, .unsubscribe-section h1 {
    color: #fff;
  }

  .newsletter-container p, .unsubscribe-section p {
    color: #fff;
  }

  .newsletter-form input[type=email] {
    background-color: #555;
    color: #ddd;
    border: 1px solid #777;
  }

  .newsletter-form button {
    background-color: #007bff;
    color: white;
    border-radius: 5px;
  }

  .newsletter-form button:hover {
    background-color: #0056b3;
  }

  .unsubscribe-section {
    background-color: #555;
    color: #ddd;
  }

  .newsletter-description, .unsubscribe-section {
    background-color: #202020;
    color: #fff;
  }
}
