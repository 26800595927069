.selector {
    width: 200px;
    height: 40px;
    font-size: 16px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  .selector:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  .selector option {
    background-color: #fff;
    color: #333;
  }
  
  .selector option:hover {
    background-color: #f0f0f0;
  }
  


div.article-type-container {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
}

button.article-type-button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
  margin-right: 10px;
}

button.article-type-button.active {
  background-color: #007bff;
  color: white;
}


button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}

h1 {
  text-align: center;
}