@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #202020;
    --text-color: #fff;
  }

  .theme-btn {
    color: #f7f7f7 !important;
    background-color: #3a3a3a !important;
    text-decoration: none;
   }

  
   .theme-btn-reset {
    color: #f7f7f7 !important;
    background-color: #dd1919 !important;
    text-decoration: none;
   }
   .theme-btn-reset:hover {
    background-color: #ff0000 !important;
  }

   .theme-btn:hover {
    background-color: #7cb2eb !important;
  }

   .theme-btn.active {
    /* Stile für den ausgewählten Button */
    background-color: #409cff !important; /* Beispiel-Hintergrundfarbe */
    color: #ffffff !important; /* Beispiel-Textfarbe */
    /* Weitere Stile anpassen */
  }

  .LoadMoreBtn {
    color:#f7f7f7 !important;
    background-color: #3a3a3a !important;
  }
  .resetButtonImportant {
    background-color: red !important;
  }
  


  .sticky-div {
    background-color: #333 !important;
    color: white !important;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative; /* Damit der close-button positioniert werden kann */
  }
  
  .newsletter-ad {
    left:5px;
    background-color: #333 !important;
    color: white !important;
  }

}


.cookieConsent {
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 90;
}

.container {
  display: flex;
  align-items: center;
  gap: 8px; /* Abstand zwischen den Elementen */
}

.theme-btn {
  color: #f7f7f7;
  background-color: #3a3a3a;
  text-decoration: none;
  border: none; /* Entfernt die Standard-Bordüre */
  padding: 10px 20px; /* Innenabstand hinzufügen */
  cursor: pointer; /* Cursor ändern, wenn man über den Button fährt */
  border-radius: 0; /* Runde Ecken entfernen */
}


/*  Search bar */

/* Container styles */
.search-container {
  display: flex; 
  justify-content: center; 
  padding: 10px; 
}

/* Search input styles */
.search-input {
  border: none;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 4px; 
  margin-right: 10px;
  width: 300px; 
  outline: none; 
}

/* Search button styles */
.search-btn {
  border: 2px solid #007BFF !important;
  height: 42px;
  padding: 10px 20px; 
  font-size: 16px; 
  background-color: #007BFF;
  color: white; 
  border: none; 
  border-radius: 4px;
  cursor: pointer; 
  outline: none; 
}

.search-btn:hover {
  background-color: #0056b3; 
}


/* When the screen is less than 600px wide, stack the links and the search field vertically instead of horizontally */
@media screen and (max-width: 600px) {
  .topnav a, .topnav input[type=text] {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .topnav input[type=text] {
    border: 1px solid #ccc;
  }
}



@media (min-width: 1620px) {
  .newsletter-ad {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }

  .sticky-div {
    display: none;
  }
}

@media (max-width: 1620px) {
  .newsletter-ad {
    display: none;
  }

  .sticky-div {
    display: block; 
  }
}

.close-button {
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.sticky-div {
  background-color: white;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative; 
}

.newsletter-ad {
  left:5px;
  background-color: white;
  color: #333;
  padding: 5px;
  border-radius: 10px;
}

/* Für große Bildschirme */
@media (min-width: 1621px) {
  .newsletter-ad {
    width: 250px; 
  }
}

/* Für kleine Bildschirme */
@media (max-width: 1620px) {
  .sticky-div {
    text-align: center;
    margin: 10px 0;
  }

}

@media (max-width: 750px) {
  .cookieConsent {
    top: 82% !important;
  }
}

.body {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    align-content: center;
  }
  
  .box {
    position: relative;
    margin-bottom: 20px; 
  }
  
  
.IndexPageSettings {
  margin-bottom: 20px;
}


.horizontalThemeList {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start; 
  align-items: center;
  opacity: 1;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0 10px; 
  padding: 5px 0; 
}

.theme-btn, .theme-btn-reset {
  flex: 0 0 auto;
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  background-color: #d8d8d8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.theme-btn-reset {
  color: #fff;
  background-color: #e22222;
}

  
  .theme-btn-reset:hover{
    background-color: #ff0000;
  }
  .theme-btn.active {
    /* Stile für den ausgewählten Button */
    background-color: #409cff; /* Beispiel-Hintergrundfarbe */
    color: #ffffff; /* Beispiel-Textfarbe */
    /* Weitere Stile anpassen */
  }


  .theme-btn:hover {
    background-color: #7cb2eb;
  }
  
  .theme-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
  }



.LoadMoreBtn {
  border-radius: 5px;
  color: #3a3a3a;
  background-color: #f7f7f7;
  text-decoration: none;
  margin: 0 auto;
}

.resetButtonImportant {
  background-color: red !important;
}


/* Laden... Anzeige*/

#loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25, 25, 25, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 1.2s ease-in-out;
}

#loader.hidden {
  opacity: 0;
  visibility: hidden;
  transition: none;
}

.loader-icon {
  width: 100px;
  height: 100px;
  border: 6px solid #707070;
  border-top-color: #00aeff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  animation: spin 2s infinite linear;
  opacity: 1;
  border-radius: 50%;
}


.loader-text {
  color: #fff;
  margin-left: 10px;
  font-size: 24px;
  font-weight: bold;
  opacity: 0.9;
}


@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);

  }
  50% {
    transform:  rotate(360deg) scale(0.6);
  }
  100% {
    transform: rotate(720deg) scale(1);
  }
}


div.post {
  padding: 15px; /* Increase padding for a larger space inside the posts */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

@media (max-width: 700px) {
  div.post {
    display: block;
    margin-bottom: 15px; /* Add space between posts on smaller screens */
  }
  div.post .image {
    margin-bottom: 15px; /* Add space below the image inside the post */
  }
}


