:root {
  --background-color: #fff;
  --text-color: #333;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #202020;
    --text-color: #fff;
  }

a {
  color: #00b7ff;
}

div.post p.info {
  color: #8a8a8a !important;

}

.Article {
  background-color: #171717 !important;
  border-radius: 11px;
}

  header {
    background-color: var(--background-color);
    color: var(--text-color);
    transition: 0.5s;
  }
  

    /* Helle Version von ReactQuill */
  .quill {
    background-color: #ffffff;
    color: #000000;
  }

  
  input {
  color: black;
  }


  .IndexPageError {
  background-color: #810e0e !important;
  color: white !important;
  }


  /* Footer*/
  @media (prefers-color-scheme: dark) {
    .footer {
      background-color: #1a1a1a !important;
      color: rgb(255, 255, 255) !important;
      text-align: center;
    }

    .footer-section a.links {
      color: rgb(255, 255, 255);
    }
  }

  @media (max-width: 768px) { 
    .footer-content {
      flex-direction: column;
    }

    .footer-section {
      margin: 0 auto;
    }
  }

  @media (max-width: 480px) {
    .footer-section {
      max-width: 100%; 
    }
  }
  a:hover {
    color: #ffffff;
  }
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Ab hier der Whitemode code*/

.topic{
  text-align: center;
}


img{
  max-width: 100%;

}

main{  
padding: 15px;
max-width: 1050px;
margin: 0 auto;
}

header {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  align-items: center;
  margin-top: 20px;
}


header a {
  font-size: 1.2rem;
  text-decoration: none;
  color: inherit;
}

.header-logo-container {
  display: flex;
  align-items: center;
}

header a.logo {
  font-weight: bold;
  font-size: 2rem;
}

.ql-size-large {
  font-size: 25px;
}

.ql-size-huge {
  font-size: 35px;
}

header nav{
  display: flex;
  gap: 20px;
}


.Article {
  background-color: #d5d5d5;
  border-radius: 11px;
}


@keyframes popIn {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pop-in {
  animation: popIn 0.35s ease-out forwards;
}

.Post {
  transform: scale(0);
}



div.post {
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  gap: 20px;
  margin-bottom: 25px;
  
  border-radius: 11px; /* das hier um die rundung zu entfernen, wenn man eine andere hintergrundfarbe hat*/
}
.rounded-image {
  border-radius: 10px;
  max-height: 350px;
  overflow: hidden;
  display: flex;
  justify-content: center; /* Zentriert das Bild horizontal */
  align-items: center; /* Zentriert das Bild vertikal */
  width: 100%; /* Fügt die Breite hinzu, damit das Bild gestreckt wird */
}

.rounded-image img {
  object-fit: cover;
  object-position: center; /* Dies zentriert das Bild und schneidet es oben und unten ab */
  width: 100%; /* Stellt sicher, dass die Breite des Bildes den Container ausfüllt */
  height: auto; /* Passt die Höhe automatisch an */
}


div.post div.texts h2{
  margin: 0;
  font-size: 1.7rem;
}
div.post div.texts a {
text-decoration: none;
color: inherit;
}
div.post span.info {
  margin: 0;
  color: #6f6f6f;
  font-size: .8rem;
  font-weight: bold;
  display: flex;
  gap: 10px;
}


div.post p.summary{
  line-height: 1.4rem;
}


form.login, form.register{
  margin: 0 auto;
  max-width: 450px;
}
input{
  font-size: 1rem;
  display: block;
  margin-bottom:  5px;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #eee;
  box-sizing: border-box;

}

button{
  display: block;
  color:#000000;
  font-size: 1.2rem;
  border: 1px solid #999;
  transition: 0.5s;
}
button:hover {
  background-color: rgb(150, 150, 150);
  cursor: pointer;
  transition: 0.5s;
}


form.login h1, form.register h1{
text-align: center;
}



.IndexPageError{

  border: 2px solid red;
  padding: 5px;
  background-color: #FFCCCA;
  border-radius: 10px;
  margin-bottom: 10px;
  color: #202020;
}



.Info{

  border: 3px solid rgb(255, 238, 0);
  padding: 10px;
  background-color: #ddd98f;
  border-radius: 10px;
  margin-bottom: 10px;
  color: #202020;
}



/* Footer */
.footer {
  background-color: #f0f0f0;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.footer-section {
  flex: 1;
  padding: 0 20px;
}

.footer-section h3 {
  margin-bottom: 15px;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.footer-bottom {
  border-top: 1px solid #777;
  padding-top: 10px;
}

a:hover {
  color: #3f3f3f;
}

/*! not used currently*/
@keyframes rainbow-text {
  0% { color: red; }
  14% { color: orange; }
  28% { color: rgb(182, 182, 0); }
  42% { color: green; }
  57% { color: blue; }
  71% { color: indigo; }
  85% { color: violet; }
  100% { color: red; }
}

a.rainbow-text {
  animation: rainbow-text 7.5s linear infinite !important;
}
