.AboutUs-storyDesc.short {
  overflow: hidden;
}


/* About Us Websitenteil - Whitemode*/

.AboutUs-desc {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: center;
}

.AboutUs-teamTitle {
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: center;
}

.AboutUs-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.AboutUs-title {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 40px;
}

.AboutUs-teamMemberImg {
    width: 150px;
    height: 150px;
    align-self: center; /* Bild vertikal zentrieren */
    border-radius: 10px;
}

.AboutUs-team {
    margin-top: 40px;
}

.AboutUs-teamMembers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width: 760px) {
    .AboutUs-teamMembers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .AboutUs-teamMember {
        margin: 20px 0;
    }
}

.AboutUs-teamMember {
    margin: 0 20px;
    text-align: center;
}
