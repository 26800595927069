/* Post Page CSS */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #202020;
    --text-color: #fff;
  }
}

div.post-page time{
  text-align: center;
  display: block;
  font-size: 0.8rem;
  color: #adadad !important;
  margin: 5px;
}

div.post-page div.author{
  text-align: center;
  margin: 20px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #c2c2c2 !important;
}
  
.edit_row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap; 
  margin: 15px;
  gap: 10px;
}

.edit, .delete, .selector-container {
  margin-bottom: 10px; /* Fügt Abstand nach jedem Element hinzu */
}
.button-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px; 
}


/*Settings menu*/
.settings-btn {
  
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #d0d0d0;
  background-color: #474747;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 10px; 
  margin-right: 10px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.3);
}
.settings-btn:hover {
  background-color: #a8a8a8;
}

/* buttons and menus*/
.back-btn, .edit, .delete, .select-style {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #d0d0d0;
  background-color: #474747;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 10px; 
  margin-right: 10px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.3);
}


.back-btn:hover, .edit:hover, .delete:hover, .select-style:hover {
  background-color: #a8a8a8;
}



.delete:hover {
  cursor: pointer;
  background-color: #99130f;
  color: #ffffff;
}

.selector-container {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  width: 300px; 
  max-width: 280px; 
  margin-bottom: 20px; 
}

.selector-container label, .select-style {
  font-size: 16px;
  font-weight: bold;
  color: #d0d0d0;
  background-color: #474747;
  padding: 10px 20px;
  border-radius: 4px;
  width: 100%;
  margin: 7px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0,0,0,0.3);
}




div.post-page time{
  text-align: center;
  display: block;
  font-size: 0.8rem;
  color: #292929;
  margin: 5px;
}
  
div.post-page div.author{
  text-align: center;
  margin: 20px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #1b1b1b;
}
  
  
div.post-page h1{
  text-align: center;
  margin: 15px 0 15px;
}
  
  
div.post-page div.content p{
  line-height: 1.5rem;
  font-size: large;
}

.post-page div.image{
  max-height: 500px;
  max-width: 600px;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
}
@media screen and (min-width: 650px) {
  div.content p{
    font-size: 22px !important;
  }
  h2 {
    font-size: 30px;
  }
}
.post-page div.image img{
  object-fit: cover;
  object-position: center center;
}

.back-btn:hover {
  background-color: #7a828b;
}


/* textstile selector */
.default-style {
  font-family: 'Times New Roman', serif;
}

.alternate-style-1 {
  font-family: Arial, sans-serif;
}

.alternate-style-2 {
  font-family: 'Courier New', monospace;
}

/* Apply similar styles to the text-style selector */
.select-style {
  background-color: #707070;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: calc(100% - 40px);
  box-sizing: border-box;
}

/* Style for the dropdown arrow */
.select-style:after {
  content: '▼';
  color: #fff;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Hover state for the selector */
.select-style:hover {
  background-color: #858585;
}

