/* Login Page Container Styles */
.login-container {
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    margin: 10px auto;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
    text-align: center;
    line-height: 1.6;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
  }
  
  /* Login Form Styles */
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
  }
  
  .login-form input[type=text], .login-form input[type=password], .login-form button {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.5);
    width: 60%; 
  }
  
  /* Button Styles */
  .login-form button {
    background-color: #007bff;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.5s linear;
  }

  .login-form button:hover {
    background-color: #0056b3;
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.4);
  }
  
  /* Error Message Styles */
  .IndexPageError {
    max-width: 300px;
    margin: 20px auto;
    border-radius: 10px;
    text-align: center;
    background-color: #f44336;
    color: white;
  }
  
  /* Dark Mode and Responsive Styles */
  @media (prefers-color-scheme: light) {
    /* Similar to .newsletter-container in dark mode */
    /* Add your styles here */
  }
  
