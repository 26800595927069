/* Dark Mode Override for Newsletter Verify */
@media (prefers-color-scheme: dark) {
    .newsletter-verify {
      background-color: #333; /* Using specific dark mode color */
      color: #f4f4f4; /* Using specific dark mode text color */
    }
  }
  
  /* Styles for the main container */
  .newsletter-verify {
    background-color: var(--background-color); /* Using global variable */
    color: var(--text-color); /* Using global variable */
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    margin: 20px auto;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
    text-align: center;
  }
  
  /* Styling for the title and instruction elements */
  .newsletter-title, .newsletter-instruction {
    line-height: 1.6;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: bold;
  }
  
  /* Styles for the form */
  .newsletter-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
  }
  
  /* Styles for the input and submit button */
  .newsletter-form input[type=text], .newsletter-form button {
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.2);
    width: 50%; 
  }
  
  /* Styling for the submit button */
  .newsletter-submit-button {
    background-color: #007bff;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.5s linear;
  }
  
  .newsletter-submit-button:hover {
    background-color: #0056b3;
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.4);
  }
  