:root {
  --background-color: #ffffff;
  --text-color: #000;
  --hamburger-color: #3b3b3b;
}


.hamburger div {
  background-color: var(--hamburger-color);
}

.side-menu {
  background-color: var(--background-color);
  color: var(--text-color);
}

.side-menu a {
  color: var(--link-color);
}

/* Dunkler Modus basierend auf den Systemeinstellungen des Benutzers */
@media (prefers-color-scheme: dark) {
  :root {
      --background-color: #202020;
      --text-color: #fff;
      --link-color: #ffffff; 
      --hamburger-color: #ffffff; /* Weißer Hamburger im Dunkelmodus */
  }
  
  .side-menu a::before {
      color: var(--text-color);
  }

  .imp {
      color: #999999; /* Graue Farbe im Dunkelmodus für .imp */
  }

  .side-menu {
    background-color: #080808 !important;
    color: #fff;
  }
}



.hamburger {
    width: 30px;
    height: 22px;
    position: fixed;
    right: 15px;  /* Setzen Sie das Hamburger-Icon auf die rechte Seite */
    top: 25px;    /* Zentriert das Hamburger-Icon vertikal */
    transform: translateY(-50%); /* Dies verschiebt das Hamburger-Icon um die Hälfte seiner Höhe nach oben, um es vertikal zu zentrieren */
    cursor: pointer;
    z-index: 10;
  }
  
  .hamburger div {
    width: 100%;
    height: 4px;
    background-color: #555555;
    position: absolute;
    left: 0;
    transition: 0.3s;
  }
  
  .hamburger div:nth-child(1) { top: 0; }
  .hamburger div:nth-child(2) { top: 50%; transform: translateY(-50%); }
  .hamburger div:nth-child(3) { bottom: 0; }
  
  .side-menu {
    position: fixed;
    right: -350px; 
    top: 0;
    bottom: 0;
    width: 300px;
    background-color: #dcdcdc;
    transition: 0.5s;
    z-index: 5;
    padding: 20px;
    opacity: 0.1;
  }
  
  .side-menu.open {
    right: 0;
    opacity: 1;
  }

  .side-menu div {
    margin-bottom: 20px; /* Du kannst diesen Wert anpassen, um den gewünschten Abstand zu erreichen */
  }
.side-menu .newsletter {
    margin-top: 10px;
}

/* Erstes Icon */
.side-menu .newsletter p::before {
    content: "\📧"; /* Hier das Unicode-Zeichen oder die Icon-Klasse deines gewünschten Icons einfügen */
    font-family: "Font Awesome"; /* Ersetze "Font Awesome" durch die entsprechende Icon-Schriftart */
    margin-right: 10px; /* Abstand zwischen Icon und Link-Text */
  }
  
  /* Zweites Icon */
  .side-menu .redakteur p::before {
    content: "\📰"; 
    font-family: "Font Awesome"; 
    margin-right: 10px; 
  }
  
  /* Drittes Icon */
  .side-menu .mitmachen p::before {
    content: "👤"; 
    font-family: "Font Awesome"; 
    margin-right: 10px; 
  }
  
  /* Viertes Icon */
  .side-menu .about p::before {
    content: "ℹ";
    font-family: "Segoe UI Emoji"; 
    margin-right: 10px;
  }
  
  /* Fünftes Icon */
  .side-menu .imp p::before {
    content: "ℹ"; 
    font-family: "Segoe UI Emoji"; 
    margin-right: 10px; 
  }
  
  .side-menu .logout a::before {
    content: "🔓"; 
    margin-right: 10px; 
  }

  .side-menu .admin p::before {
    content: "❗"; 
    margin-right: 10px; 
  }

.imp {
    position: absolute;
    top: 95%;
    color: #161616;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: none; 
  z-index: 4; 
}

.overlay.open {
  display: block;
}
